import axios from "@/api/restApi"

export async function detectFaceLandmark(url) {
  const res = await axios.post("/api/image_edit/face_landmark", {
    params: {
      image_url: url,
    },
  })
  return res.data
}
