function rotateCanvas90(origCanvas, clockwise = true) {
  const w = origCanvas.width,
    h = origCanvas.height
  const newC = document.createElement("canvas")
  newC.width = h
  newC.height = w
  const ctx = newC.getContext("2d")
  if (clockwise) {
    ctx.translate(h / 2, w / 2)
    ctx.rotate(Math.PI / 2)
    ctx.drawImage(origCanvas, -w / 2, -h / 2)
  } else {
    // 반시계
    ctx.translate(h / 2, w / 2)
    ctx.rotate(-Math.PI / 2)
    ctx.drawImage(origCanvas, -w / 2, -h / 2)
  }
  return newC
}

// ============ makeGrid (상하좌우 mm + 사진테두리) ============
function makeGrid(sourceCanvas, { ratio = 3 / 4, rows = 3, cols = 3, gapMm = 2, marginTopMm = 2, marginRightMm = 2, marginBottomMm = 2, marginLeftMm = 2 }) {
  const CANVAS_W = 1333,
    CANVAS_H = 2000
  const c = document.createElement("canvas")
  c.width = CANVAS_W
  c.height = CANVAS_H
  const ctx = c.getContext("2d")

  ctx.fillStyle = "#fff"
  ctx.fillRect(0, 0, CANVAS_W, CANVAS_H)

  const pxPerMm = 13.3

  // 상하좌우 변환
  const topPx = marginTopMm * pxPerMm
  const rightPx = marginRightMm * pxPerMm
  const bottomPx = marginBottomMm * pxPerMm
  const leftPx = marginLeftMm * pxPerMm

  // usable area
  const usableW = CANVAS_W - (leftPx + rightPx)
  const usableH = CANVAS_H - (topPx + bottomPx)

  // gap px
  const gapPx = gapMm * pxPerMm

  // 각 사진 폭
  const photoW = (usableW - (cols - 1) * gapPx) / cols
  const photoH = photoW / ratio

  let totalH = rows * photoH + (rows - 1) * gapPx
  let scale = 1.0
  if (totalH > usableH) {
    scale = usableH / totalH
  }
  const finalW = photoW * scale
  const finalH = photoH * scale
  const finalGap = gapPx * scale

  const usedTotalH = rows * finalH + (rows - 1) * finalGap
  const offX = leftPx
  const offY = topPx + (usableH - usedTotalH) / 2 // 수직중앙 정렬

  // 테두리 스타일: 1px, 검정(투명도 20%)
  ctx.strokeStyle = "rgba(0,0,0,0.2)"
  ctx.lineWidth = 1

  for (let r = 0; r < rows; r++) {
    for (let cIdx = 0; cIdx < cols; cIdx++) {
      const dx = offX + cIdx * (finalW + finalGap)
      const dy = offY + r * (finalH + finalGap)

      // 사진 그리기
      ctx.drawImage(sourceCanvas, 0, 0, sourceCanvas.width, sourceCanvas.height, dx, dy, finalW, finalH)

      // 사진 테두리 라인 (1px)
      ctx.strokeRect(dx, dy, finalW, finalH)
    }
  }

  // JPEG 품질을 0.95로 설정 (95%)
  return new Promise((resolve) => {
    c.toBlob(resolve, "image/jpeg", 1.0)
  })
}

export async function generateGridPrintImageFile(srcCanvas, printType) {
  const { midRatio, rotateFinal, rows, cols } = printType.alignment

  let finalCanvas = document.createElement("canvas")
  finalCanvas.width = srcCanvas.width
  finalCanvas.height = srcCanvas.height
  finalCanvas.getContext("2d").drawImage(srcCanvas, 0, 0, finalCanvas.width, finalCanvas.height)

  let usedRatio = midRatio
  if (rotateFinal) {
    finalCanvas = rotateCanvas90(finalCanvas, true)
    usedRatio = 1 / midRatio
  }

  const gapMm = 2

  const blob = await makeGrid(finalCanvas, {
    ratio: usedRatio,
    rows,
    cols,
    gapMm,
    marginTopMm: 3,
    marginRightMm: 3,
    marginBottomMm: 4,
    marginLeftMm: 3,
  })

  return new File([blob], "grid-print.jpg", { type: "image/jpeg" })
}
