export const PrintTypes = [
  {
    id: 0,
    title: "3.5x4.5cm",
    purpose: "주민등록증/면허증 등",
    alignment: {
      topY: 280,
      botY: 640,
      midRatio: 3.5 / 4.5,
      rows: 4,
      cols: 2,
      rotateFinal: true,
      guideLine1: 150,
    },
  },
  {
    id: 1,
    title: "3x4cm",
    purpose: "학생증 / 회사제출 등",
    alignment: {
      topY: 280,
      botY: 640,
      midRatio: 3 / 4,
      rows: 3,
      cols: 3,
      rotateFinal: false,
      guideLine1: 150,
    },
  },
  {
    id: 2,
    title: "여권",
    subtitle: "3.5x4.5cm",
    purpose: "여권발급용 사진",
    alignment: {
      topY: 300,
      botY: 780,
      midRatio: 3.5 / 4.5,
      rows: 4,
      cols: 2,
      rotateFinal: true,
      guideLine1: 100,
    },
  },
]

export function getPrintType(id) {
  return PrintTypes.find((type) => type.id === Number(id))
}
