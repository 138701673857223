<template>
  <v-sheet class="rounded-lg text-center">
    <v-card class="py-4 py-md-10 px-2" :class="isFaceberry ? 'faceberry-card' : ''">

      <v-img src="@/assets/print/print-complete_faceberry.png" class="background-image" v-if="isFaceberry" />

      <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" v-if="!isFaceberry" />

      <div class="font-danjunghae primary--text text-center"
        :style="{ fontSize: $vuetify.breakpoint.mobile ? '28px' : '36px' }" :lang="lang">{{
          $t(getResourceKey("page.picturePrintReq.complete-title")) }}</div>

      <v-card-title class="text-h6 text-md-h5 text-center mx-auto font-weight-light d-block mt-0 pt-0"
        style="color: #545454">
        {{ $t("page.picturePrintReq.complete-subtitle") }}
      </v-card-title>

      <template v-if="!isFaceberry">
        <v-img src="@/assets/print/print-complete.png" max-width="60%" class="mx-auto my-4 my-md-8" />
        <v-sheet class="px-8">
          <v-progress-linear v-model="progress" height="35" rounded color="primary" style="pointer-events: none">
            <strong class="white--text">{{ progress }}% </strong>
          </v-progress-linear>
        </v-sheet>
      </template>
      <template v-else>
        <v-sheet class="px-8 mt-16" style="margin-bottom: 110px;">
          <v-progress-linear v-model="progress" height="35" rounded color="primary" style="pointer-events: none">
            <strong class="white--text">{{ progress }}% </strong>
          </v-progress-linear>
        </v-sheet>
      </template>

      <v-card-actions class="d-flex justify-center mt-10">
        <v-btn color="#d9bddd" class="dialog_btn rounded-2" style="font-size: 20px" width="35%" depressed :to="retUrl"
          large replace :disabled="progress !== 100"> {{ $t("button.ok") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex"
export default {
  data: () => ({
    progress: 0,
  }),
  computed: {
    ...mapState(["lang"]),
    retUrl() {
      return "/mypage/home?type=picture"
    },

    isFaceberry() {
      return (this.$route.query.kiosk || '').startsWith('faceberry')
    },
  },

  mounted() {
    this.startProgress()
  },

  methods: {
    startProgress() {
      const duration = 5000
      const interval = 200
      const step = (100 / duration) * interval

      let intervalId = setInterval(() => {
        this.progress += step
        if (this.progress >= 100) {
          this.progress = 100
          clearInterval(intervalId)
        }
      }, interval)
    },

    getResourceKey(key) {
      if (this.isFaceberry) {
        return key + '_faceberry'
      }
      return key;
    },
  },
}
</script>
<style scoped>
::v-deep .v-btn {
  color: #625072;
}

.faceberry-card {
  position: relative;
}

.background-image {
  position: absolute;
  width: 60%;
  object-fit: cover;
  opacity: 0.1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0px;
}
</style>
