import { detectFaceLandmark } from "../../api/landmark-detect"
import { removeBackground } from "../../api/remove-background"
import * as THREE from "three"

class FaceMesh {
  constructor(imageUrl) {
    this.imageUrl = imageUrl
    this.subdiv = 150
    this.texture = null
    this.planeMesh = null
    this.planeGeometry = null
    this.originalPositions = null
    this.landmarks = null
    this._brightness = 0 // 기본값 추가

    this.leftEyeCenter = { x: 0, y: 0 } // ★ 왼눈 중심
    this.rightEyeCenter = { x: 0, y: 0 } // ★ 오른눈 중심
    this.leftCheekCenter = { x: 0, y: 0 } // ★ 왼볼 중심
    this.rightCheekCenter = { x: 0, y: 0 } // ★ 오른볼 중심
    this.leftNostrilCenter = { x: 0, y: 0 } // ★ 왼콧볼 중심
    this.rightNostrilCenter = { x: 0, y: 0 } // ★ 오른콧볼 중심
    this.FaceWidthCenter = { x: 0, y: 0 }
  }

  // brightness getter/setter 추가
  get brightness() {
    return this._brightness
  }

  setBrightness(value) {
    this._brightness = value
    if (this.planeMesh?.material?.uniforms) {
      this.planeMesh.material.uniforms.brightness.value = value
    }
  }

  async load() {
    this.landmarks = await detectFaceLandmark(this.imageUrl)
    this.maskUrl = await removeBackground(this.imageUrl)

    return new Promise((resolve, reject) => {
      const textureLoader = new THREE.TextureLoader()

      // 이미지와 마스크를 동시에 로드
      Promise.all([new Promise((res, rej) => textureLoader.load(this.imageUrl, res, undefined, rej)), new Promise((res, rej) => textureLoader.load(this.maskUrl, res, undefined, rej))])
        .then(([texture, maskTexture]) => {
          texture.flipY = false
          maskTexture.flipY = false
          this.texture = texture

          const imageWidth = texture.image.width
          const imageHeight = texture.image.height
          const planeGeometry = new THREE.PlaneGeometry(imageWidth, imageHeight, this.subdiv, this.subdiv)

          // 커스텀 셰이더 머티리얼 생성
          const material = new THREE.ShaderMaterial({
            uniforms: {
              map: { value: texture },
              mask: { value: maskTexture },
              brightness: { value: 0.0 },
            },
            vertexShader: `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `,
            fragmentShader: `
            uniform sampler2D map;
            uniform sampler2D mask;
            uniform float brightness;
            varying vec2 vUv;

            void main() {
              vec4 texColor = texture2D(map, vUv);
              vec4 maskColor = texture2D(mask, vUv);
              vec3 color = texColor.rgb;
              
              float maskAlpha = maskColor.a;
              if (brightness >= 0.0) {
                // Screen blend: 1 - (1-a)(1-b)
                vec3 screen = vec3(1.0) - (vec3(1.0) - color) * (vec3(1.0) - color * brightness * maskAlpha);
                color = mix(color, screen, maskAlpha);
              } else {
                // Multiply blend: a * b
                vec3 multiply = color * (1.0 + brightness * maskAlpha);
                color = mix(color, multiply, maskAlpha);
              }
              
              gl_FragColor = vec4(color, texColor.a);
            }
          `,
            side: THREE.DoubleSide,
          })

          const planeMesh = new THREE.Mesh(planeGeometry, material)
          planeMesh.position.set(imageWidth / 2, imageHeight / 2, 0)

          const posAttr = planeGeometry.attributes.position
          const originalPositions = new Float32Array(posAttr.array.length)
          originalPositions.set(posAttr.array)

          this.planeMesh = planeMesh
          this.planeGeometry = planeGeometry
          this.originalPositions = originalPositions

          this._analyzeLandmarks()
          resolve(this)
        })
        .catch(reject)
    })
  }

  _analyzeLandmarks() {
    const landmarks = this.landmarks
    // =============================
    // 1) 왼눈
    // =============================
    let leftEyeIndices = [161, 157, 159, 160, 158, 144, 145, 153, 154, 163, 471, 469, 173]
    let sumLX = 0,
      sumLY = 0
    for (let i of leftEyeIndices) {
      sumLX += landmarks[i].x
      sumLY += landmarks[i].y
    }
    this.leftEyeCenter.x = sumLX / leftEyeIndices.length
    this.leftEyeCenter.y = sumLY / leftEyeIndices.length

    // =============================
    // 2) 오른눈
    // =============================
    let rightEyeIndices = [384, 388, 386, 387, 385, 373, 374, 380, 381, 390, 476, 474, 398]
    let sumRX = 0,
      sumRY = 0
    for (let i of rightEyeIndices) {
      sumRX += this.landmarks[i].x
      sumRY += this.landmarks[i].y
    }
    this.rightEyeCenter.x = sumRX / rightEyeIndices.length
    this.rightEyeCenter.y = sumRY / rightEyeIndices.length

    // =============================
    // 3) 왼볼
    // =============================
    let leftCheekIndices = [177, 132, 215, 58, 138, 172, 136, 150, 149]
    let sumCX = 0,
      sumCY = 0
    for (let i of leftCheekIndices) {
      sumCX += this.landmarks[i].x
      sumCY += this.landmarks[i].y
    }
    this.leftCheekCenter.x = sumCX / leftCheekIndices.length
    this.leftCheekCenter.y = sumCY / leftCheekIndices.length

    // =============================
    // 4) 오른볼
    // =============================
    // 예시 인덱스: [401,361,433,435,288,367,397,365,379,378,400]
    // 이 중 일부가 "중심"에 가깝다고 가정
    let rightCheekIndices = [401, 361, 433, 435, 288, 367, 397, 365, 379, 378, 400]
    let sumRCX = 0,
      sumRCY = 0
    for (let i of rightCheekIndices) {
      sumRCX += this.landmarks[i].x
      sumRCY += this.landmarks[i].y
    }
    this.rightCheekCenter.x = sumRCX / rightCheekIndices.length
    this.rightCheekCenter.y = sumRCY / rightCheekIndices.length

    // =============================
    // 4) 왼콧볼
    // =============================
    let leftNostrilIndices = [4, 198, 209, 49]
    let sumNX = 0,
      sumNY = 0
    for (let i of leftNostrilIndices) {
      sumNX += landmarks[i].x
      sumNY += landmarks[i].y
    }
    this.leftNostrilCenter.x = sumNX / leftNostrilIndices.length
    this.leftNostrilCenter.y = sumNY / leftNostrilIndices.length
  }

  get imageWidth() {
    return this.texture?.image.width || 0
  }

  get imageHeight() {
    return this.texture?.image.height || 0
  }

  unload() {
    if (this.maskUrl) {
      URL.revokeObjectURL(this.maskUrl)
    }
  }
}

export default FaceMesh
