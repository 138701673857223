<template>
    <button class="icon-button" @click="handleClick">
        <img :src="iconSrc" alt="printer icon" class="icon">
        <span class="button-text">{{ text }}</span>
    </button>
</template>

<script>
export default {
    name: 'IconButton',
    props: {
        iconSrc: {
            type: String,
            required: true
        },
        text: {
            type: String,
            default: '인쇄'
        }
    },
    methods: {
        handleClick() {
            this.$emit('click')
        }
    }
}
</script>

<style scoped>
.icon-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    width: 86px;
    margin-right: 5px;
    margin-left: 5px;
}

.icon-button:hover {
    background-color: #f5f5f5;
}

.icon {
    width: 36px;
    height: 36px;
}

.button-text {
    font-size: 14px;
    color: #333;
}
</style>