import axios from "@/api/restApi"

export async function removeBackground(url) {
  const res = await axios.post(
    "/api/image_edit/remove_background",
    {
      params: {
        image_url: url,
      },
    },
    {
      responseType: "blob",
    }
  )
  const imageUrl = URL.createObjectURL(new Blob([res.data]))
  return imageUrl
}
