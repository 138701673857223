<template>
    <div class="canvas-container" ref="container">
        <canvas ref="canvas" style="width: 100%; height: auto;" />
    </div>
</template>

<script>
import * as THREE from 'three';

export default {
    name: 'ThreeJSCanvasImage',
    props: {
        mesh: {
            type: Object,
            required: true
        },
        imageWidth: {
            type: Number,
            required: true
        },
        imageHeight: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            scene: null,
            camera: null,
            renderer: null
        }
    },
    mounted() {
        this.initThree();
        this.render();
    },
    methods: {
        initThree() {
            // 윈도우 리사이즈 이벤트 처리
            window.addEventListener('resize', this.onWindowResize);

            const imageWidth = this.imageWidth
            const imageHeight = this.imageHeight

            const camera = new THREE.OrthographicCamera(
                0, imageWidth,
                0, imageHeight,
                -100, 100
            );

            camera.position.set(0, 0, 10);
            camera.lookAt(0, 0, 0);

            const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: this.$refs.canvas });
            const scene = new THREE.Scene();
            scene.add(this.mesh);

            this.scene = scene
            this.camera = camera
            this.renderer = renderer

            this.onWindowResize();
        },

        render() {
            if (this.renderer && this.scene && this.camera) {
                this.renderer.render(this.scene, this.camera);
            }
        },

        updateRender() {
            this.$nextTick(() => {
                this.render();
            });
        },

        onWindowResize() {
            const container = this.$refs.container;
            const containerWidth = container.clientWidth;
            const imageWidth = this.imageWidth;
            const imageHeight = this.imageHeight;

            this.renderer.setSize(imageWidth, imageHeight);
            this.$refs.canvas.style.width = `100%`;
            this.$refs.canvas.style.height = `auto`;

            const scale = containerWidth / imageWidth;
            const scaledHeight = imageHeight * scale;
            container.style.height = `${scaledHeight}px`;

            // 카메라 업데이트
            this.camera.right = imageWidth;
            this.camera.bottom = imageHeight;
            this.camera.updateProjectionMatrix();
        },

        getScreenShotBlob() {
            return new Promise((resolve, reject) => {
                // 임시 렌더러 생성
                const tempRenderer = new THREE.WebGLRenderer({
                    antialias: true,
                    alpha: true
                });
                tempRenderer.setSize(this.imageWidth, this.imageHeight);

                // 임시 카메라 생성
                const tempCamera = this.camera.clone();
                tempCamera.right = this.imageWidth;
                tempCamera.bottom = this.imageHeight;
                tempCamera.updateProjectionMatrix();

                // 임시 렌더러로 렌더링
                tempRenderer.render(this.scene, tempCamera);

                // 스크린샷 생성
                const canvas = tempRenderer.domElement;
                canvas.toBlob((blob) => {
                    // 임시 렌더러 정리
                    tempRenderer.dispose();

                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Failed to create blob'));
                    }
                }, "image/jpeg", 1.0);
            });
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
        if (this.scene) {
            this.scene.remove(this.mesh)
        }
        if (this.renderer) {
            this.renderer.dispose();
        }
        this.scene = null;
        this.camera = null;
        this.renderer = null;
    }
}
</script>

<style scoped>
.canvas-container {
    width: 100%;
    height: auto;
}
</style>