<template>
  <div>
    <!-- <TitleLogo title="페이스베리 사진 상세보기" />
    <v-divider></v-divider> -->
    <v-sheet v-if="isLoading" class="text-center mt-10">
      <div class="font-weight-medium text-body-1 pb-4">Loading...</div>
      <v-progress-circular size="48" :width="2" color="primary" indeterminate></v-progress-circular>
    </v-sheet>

    <v-sheet v-else class="pb-4 mx-auto">

      <div class="d-flex justify-center mb-2">
        <IconButton :iconSrc="require(`@/assets/menus/save.png`)" :text="$t('button.save')"
          @click="openUrlForDownload()" />
        <IconButton :iconSrc="require(`@/assets/menus/wizard.png`)" :text="$t('button.face-edit')"
          @click="openEditInfoConfirm()" />
        <IconButton :iconSrc="require(`@/assets/menus/print.png`)" :text="$t('button.print-short')"
          @click="openPrintBanners()" />
      </div>
      <v-img class="sela-dialog-card-content" :src="item.imageUrl" :lazy-src="item.thumbnailUrl" :key="item.imageUrl"
        contain v-if="!showPrintBanners">
        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular color="white" indeterminate></v-progress-circular>
          </div>
        </template>
      </v-img>

      <div class="px-2" v-if="showPrintBanners">
        <FaceberryPrintCard :faceAlignment="faceAlignment" :printType="printTypes[0]"
          :sampleImage="require(`@/assets/menus/sam_35x45.jpg`)" @click="printPicture(printTypes[0])" />
        <FaceberryPrintCard :faceAlignment="faceAlignment" :printType="printTypes[1]"
          :sampleImage="require(`@/assets/menus/sam_3x4.jpg`)" @click="printPicture(printTypes[1])" />
        <FaceberryPrintCard :faceAlignment="faceAlignment" :printType="printTypes[2]"
          :sampleImage="require(`@/assets/menus/sam_pass.jpg`)" @click="printPicture(printTypes[2])" />
      </div>

      <div class="d-flex justify-center mb-6 mt-4">
        <v-btn width="34%" large text color="rgb(84, 84, 84)" style="border: 2px solid #e9e9e9; font-size: 22px"
          class="rounded-md font-weight-medium" to="/mypage/home">
          {{ $t("button.go-back") }}
        </v-btn>
      </div>

      <ConfirmDialog @confirm="(showEditConfirm = true) && (showEditInfoConfirm = false) && (showPrintBanners = false)"
        @cancel="showEditInfoConfirm = false" :visible="showEditInfoConfirm" :message="$t('dialog.edit-start-confirm')"
        :cancelText="$t('button.back')" :confirmText="$t('button.start-edit')">
        <template>
          <div class="d-flex flex-column align-center mt-8">
            <img :src="require(`@/assets/menus/wizard.png`)" style="width: 86px" class="mb-2" />
            <h2 style="line-height: 1.0">{{ $t('button.face-edit') }}</h2>
            <v-container fluid class="d-flex flex-column align-center">
              <p class="text-center px-1 text-body-1" v-html="$t('dialog.make-picture-subtext')"></p>
              <v-img :src="require(`@/assets/menus/faceberry_edit_guide.png`)" style="width: 35%" class="" />
            </v-container>
          </div>
        </template>
      </ConfirmDialog>

      <ConfirmDialog @confirm="editPicture()" @cancel="showEditConfirm = false" :visible="showEditConfirm"
        :cancelText="$t('button.back')" :confirmText="$t('button.start-edit')">
        <template>
          <img src="@/assets/warning.png" class="icon my-4" style="width: 60px; height: 60px;" />
          <v-container fluid class="my-2">
            <h2 class="text-center">{{ $t('dialog.faceberry-start-confirm') }}</h2>
            <p class="text-center px-1 text-body-1 mt-2" v-html="$t('dialog.faceberry-start-confirm-subtext')"></p>
          </v-container>
        </template>
      </ConfirmDialog>

    </v-sheet>
  </div>
</template>
<script>
import TitleLogo from "@/views/components/TitleLogo"
import DialogHeader from "@/views/components/DialogHeader"
import ReviewWriteDialog from "./components/ReviewWriteDialog"
import { fetchMyPictureItem } from "@/models/MyPicture"
import ConfirmDialog from "@/views/components/ConfirmDialog"
import PanelButton from "@/views/components/PanelButton"
import IconButton from "@/views/components/IconButton"
import FaceberryPrintCard from "./components/FaceberryPrintCard"
import FaceAlignment from "@/models/faceberry/face-alignment"
import { PrintTypes } from "@/models/faceberry/print-types"

export default {
  components: {
    TitleLogo,
    DialogHeader,
    ReviewWriteDialog,
    ConfirmDialog,
    PanelButton,
    IconButton,
    FaceberryPrintCard,
  },

  data: () => ({
    item: null,
    isLoading: true,
    reviewDialog: false,
    hasReview: false,
    showEditInfoConfirm: false,
    showEditConfirm: false,
    showPrintBanners: false,
    faceAlignment: null,
    printTypes: PrintTypes,
  }),

  created() { },

  mounted() {
    fetchMyPictureItem(this, this.$route.params.id)
      .then((item) => {
        this.item = item
      })
      .catch((e) => {
        console.log(e)
        alert(this.$t("alert.error_failed_get_information"))
        this.$router.back()
      })
      .finally(() => {
        this.isLoading = false
      })
  },

  methods: {
    async shareImage() {
      await this.$shareLink(this.item.imageUrl)
    },

    writeReview() {
      this.reviewDialog = true
    },

    downloadImage() {
      const a = document.createElement("a")
      a.href = this.item.imageUrl
      a.download = this.item.filename
      a.click()
    },

    openUrlForDownload() {
      this.showPrintBanners = false
      this.showEditInfoConfirm = false
      this.showEditConfirm = false
      window.open("https://front.sela-castle.com/imageViewer-v1.html?image=" + this.item.filename, "_blank")
    },

    editPicture() {
      this.$router.push(`/mypage/faceberryEdit/${this.item.id}`)
    },

    isEditable(item) {
      if (!item.editable || !item.completeDate) {
        return false
      }
      const isBaseEditableDate = "24.5.1"
      const isBaseEditableDay = this.dayjs(isBaseEditableDate, "YY.M.D", true)
      const completeDay = this.dayjs(item.completeDate, "YY.M.D", true)

      if (completeDay.isBefore(isBaseEditableDay)) {
        return false
      } else {
        return true
      }
    },

    openEditInfoConfirm() {
      this.showEditInfoConfirm = true
      this.showEditConfirm = false
      this.showPrintBanners = false
    },

    async openPrintBanners() {
      this.showPrintBanners = true
      if (!this.faceAlignment) {
        const faceAlignment = new FaceAlignment(this.item.imageUrl)
        await faceAlignment.load()
        this.faceAlignment = faceAlignment
      }
    },

    printPicture(printType) {
      this.$router.push({
        path: `/mypage/faceberryPrintEdit/${this.item.id}`,
        query: {
          printType: printType.id,
        },
      })
    },
  },
}
</script>
<style></style>
