import { detectFaceLandmark } from "../../api/landmark-detect"

const loadImage = async (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = url
    image.setAttribute("crossorigin", "anonymous")
    image.onload = () => {
      resolve(image)
    }
    image.onerror = () => {
      console.error(`Failed to load image: ${url}`)
      resolve(null)
    }
  })
}

function autoAlignBoundingBox(landmarks, srcCanvas, dstW, dstH, topY, botY) {
  let yMin = Infinity,
    yMax = -Infinity
  for (const lm of landmarks) {
    let ly = lm.y
    if (ly < yMin) yMin = ly
    if (ly > yMax) yMax = ly
  }
  let distOriginal = yMax - yMin
  if (distOriginal <= 0) return null

  let distTarget = botY - topY
  let s = distTarget / distOriginal

  // x중심(#10,#8,#17,#152)
  const idxs = [10, 8, 17, 152]
  let sumX = 0,
    c = 0
  for (const i of idxs) {
    if (landmarks[i]) {
      sumX += landmarks[i].x
      c++
    }
  }
  let faceCX = c > 0 ? sumX / c : srcCanvas.width / 2

  let dy = topY - yMin * s
  let dx = dstW / 2 - faceCX * s

  let drawW = srcCanvas.width * s
  let drawH = srcCanvas.height * s

  return {
    baseScale: s,
    baseDx: dx,
    baseDy: dy,
    drawW,
    drawH,
  }
}

class FaceAlignment {
  constructor(imageUrl) {
    this.imageUrl = imageUrl
  }

  async load(alignment = null) {
    this.image = await loadImage(this.imageUrl)
    this.landmarks = await detectFaceLandmark(this.imageUrl)
    if (alignment) {
      this.setAlignment(alignment)
    }
    return this
  }

  setAlignment(alignment) {
    this.midW = 800
    this.midH = Math.round(this.midW / alignment.midRatio)

    const alignRes = autoAlignBoundingBox(this.landmarks, this.image, this.midW, this.midH, alignment.topY, alignment.botY)
    if (alignRes == null) {
      throw new Error("Failed to align face")
    }
    this.alignRes = alignRes
    this.guideLine1Y = alignment.guideLine1
  }

  render(canvas, alignment, manualScale = 1.0, manualDx = 0, manualDy = 0) {
    if (this.alignRes == null) {
      this.setAlignment(alignment)
    }

    canvas.width = this.midW
    canvas.height = this.midH

    const { baseScale, baseDx, baseDy, drawW, drawH } = this.alignRes

    const editCtx = canvas.getContext("2d")
    editCtx.clearRect(0, 0, canvas.width, canvas.height)

    const finalScale = baseScale * manualScale
    const finalDx = baseDx + manualDx
    const finalDy = baseDy + manualDy
    const finalW = this.image.width * finalScale
    const finalH = this.image.height * finalScale

    editCtx.drawImage(this.image, finalDx, finalDy, finalW, finalH)

    this.guideLine152Y = null
    if (this.landmarks[152]) {
      let lm152y = this.landmarks[152].y
      let finalScale = baseScale
      let finalDy = baseDy
      let finalY = finalDy + lm152y * finalScale
      this.guideLine152Y = finalY
    }
  }
}

export default FaceAlignment
