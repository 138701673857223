<template>
  <div class="mb-2 pa-2 pa-md-4"
    style="position: relative; border: 5px solid #cecece; border-radius: 16px; cursor: pointer;"
    @click="$emit('click')">

    <div class="d-flex justify-space-between px-4" style="align-items: center">

      <div class="">
        <div>
          <span class="accent--text" style="font-size: 28px">
            {{ printType.title }}
          </span>
          <span class="" style="font-size: 14px" v-if="printType.subtitle">({{
            printType.subtitle }})</span>
        </div>

        <div class="text-body-1 grey--text text--darken-1 font-weight-medium">
          {{ printType.purpose }}
        </div>
      </div>

      <img ref="image" class="faceberry-print-banner-image" />

    </div>
  </div>
</template>


<script>
export default {
  name: "FaceberryPrintCard",
  props: {
    faceAlignment: {
      type: Object,
    },
    printType: {
      type: Object,
      required: true,
    },
    sampleImage: {
      type: String,
    },
  },
  mounted() {
    this.render();
  },
  watch: {
    faceAlignment(newVal) {
      this.render();
    },
    printType(newVal) {
      this.render();
    },
  },
  methods: {
    render() {
      if (this.sampleImage) {
        this.$refs.image.src = this.sampleImage;
        return;
      }
      const canvas = document.createElement('canvas')
      this.faceAlignment?.render(canvas, this.printType.alignment)
      this.$refs.image.src = canvas.toDataURL();
    }
  }
}
</script>

<style scoped>
.faceberry-print-banner-canvas {
  width: 15%;
  border: 1px solid #eee;
}

.faceberry-print-banner-image {
  width: 15%;
  border: 1px solid #eee;
}
</style>